import React from 'react';
import { css } from '@emotion/core';
import { useDropzone } from 'react-dropzone';
import firebase from 'firebase/app';
import _ from 'lodash';
import fp from 'lodash/fp';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import addIcon from '@fortawesome/fontawesome-free/svgs/solid/plus.svg';
import addCircleIcon from '@fortawesome/fontawesome-free/svgs/solid/plus-circle.svg';

import { Button, Group, ImageText } from '@myungsoo/components';
import { AdminPageBase } from '@myungsoo/base/components';

const SortableItem = SortableElement(({ children }) => <div>{children}</div>);

const SortableList = SortableContainer(({ children }) => (
  <div>
    {React.Children.map(children, (child, index) => (
      <SortableItem index={index}>{child}</SortableItem>
    ))}
  </div>
));

const Home = ({ pageId, config }) => {
  const { DB_ROOT_PATH, STORAGE_ROOT_PATH } = config;

  const [loading, setLoading] = React.useState(false);
  const [images, setImages] = React.useState([]);

  const [, storageRef] = React.useMemo(() => {
    const storage = firebase.storage();
    const storageRef = storage.ref();
    return [storage, storageRef];
  }, []);

  const [db, imagesRef] = React.useMemo(() => {
    const db = firebase.firestore();
    const imagesRef = db.collection(
      `${DB_ROOT_PATH}/pages/${pageId}/backgroundImages`,
    );
    return [db, imagesRef];
  }, [DB_ROOT_PATH, pageId]);

  const onDrop = React.useCallback(async (acceptedFiles) => {
    setLoading(true);
    try {
      const imageDir = `${STORAGE_ROOT_PATH}/home-background-images`;
      await Promise.all(
        fp.map(async (file) => {
          const path = `${imageDir}/${file.name}`;
          const ref = storageRef.child(path);
          await ref.put(file);

          const url = await ref.getDownloadURL();

          await imagesRef.doc(file.name).set({
            name: file.name,
            path,
            url,
            displayOrder: -1,
            createdAt: new Date(),
          });
        })(acceptedFiles),
      );
    } finally {
      setLoading(false);
    }
  }, [STORAGE_ROOT_PATH, imagesRef, storageRef]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop,
  });

  React.useEffect(() => {
    const unsubscribe = imagesRef.orderBy('createdAt', 'desc').onSnapshot(
      (snapshot) => {
        const loadedImages = [];
        snapshot.forEach((image) => {
          loadedImages.push({
            id: image.id,
            ...image.data(),
          });
        });
        setImages(_.orderBy(loadedImages, ['displayOrder']));
      },
      (error) => {
        console.error(error);
      },
    );

    return unsubscribe;
  }, [imagesRef]);

  const removeImage = React.useCallback(async (image) => {
    if (!window.confirm('Are you sure?')) {
      return;
    }

    setLoading(true);
    try {
      await imagesRef.doc(image.id).delete();
      await storageRef.child(image.path).delete();
    } finally {
      setLoading(false);
    }
  }, [imagesRef, storageRef]);

  const saveImages = React.useCallback(async (newImages) => {
    setLoading(true);
    try {
      const batch = db.batch();

      _.forEach(newImages, ({ id, ...image }) => {
        if (id) {
          batch.update(imagesRef.doc(id), image);
        } else {
          batch.set(imagesRef.doc(), {
            ...image,
            displayOrder: -1,
            createdAt: new Date(),
          });
        }
      });

      await batch.commit();
    } finally {
      setLoading(false);
    }
  }, [db, imagesRef]);

  const onSortEnd = React.useCallback(({ oldIndex, newIndex }) => {
    if (newIndex === oldIndex) {
      return;
    }
    const sortedImages = _.map(
      arrayMove(images, oldIndex, newIndex),
      (image, index) => ({
        ...image,
        displayOrder: index,
      }),
    );
    setImages(sortedImages);
    saveImages(sortedImages);
  }, [images, saveImages]);

  return (
    <AdminPageBase
      id={pageId}
      config={config}
      showContent={false}
      loading={loading}
    >
      <div
        {...getRootProps()}
        css={css`
          height: 50vw;
          max-height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          :hover {
            background: rgba(255, 255, 255, 0.1);
          }
        `}
      >
        <input {...getInputProps()} />
        <ImageText
          css={css`
            width: 64px;
            height: 64px;
          `}
          src={isDragActive ? addCircleIcon : addIcon}
        >
          Add
        </ImageText>
      </div>
      <SortableList
        lockAxis="y"
        useWindowAsScrollContainer
        lockToContainerEdges
        onSortEnd={onSortEnd}
      >
        {fp.map((image) => (
          <div
            key={image.id}
            css={css`
              position: relative;
              /* width: 100%; */
              height: 50vw;
              max-height: 200px;

              background: url(${image.url});
              background-size: cover;
              background-position: center;

              opacity: 0.75;

              cursor: move;

              :hover {
                opacity: 1;
              }

              :hover ${Group} {
                display: block;
              }
            `}
          >
            <img
              css={css`
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: none;
              `}
              src={image.url}
              alt={image.name}
            />
            <div
              css={css`
                position: absolute;
                left: 0;
                bottom: 0;

                margin: 0.25rem;
                padding: 0.15rem 0.3rem;

                font-size: 0.7rem;

                background: rgba(0, 0, 0, 0.75);
              `}
            >
              {image.name}
            </div>
            <Group
              css={css`
                position: absolute;
                top: 0;
                right: 0;
                display: none;
              `}
            >
              <Button onClick={() => removeImage(image)}>Remove</Button>
            </Group>
          </div>
        ))(images)}
      </SortableList>
    </AdminPageBase>
  );
};

export default Home;
