import React from 'react';

import Home from '@myungsoo/base/pages/admin/Home';

import AdminLayout from '../../components/AdminLayout';
import Seo from '../../components/Seo';

import * as config from '../../../config';

export default () => (
  <AdminLayout>
    <Seo title="Admin" />
    <Home pageId="home" config={config} />
  </AdminLayout>
);
